<template>
    <div class="gnb-view">
        <div class="menu-bar">
            <img class="logo" src="@/assets/logo.png" style="cursor: pointer;" @click="doRoute({title: '', route:'home'})">
            <div class="menu-item" v-for="x of menu_items" :key="x.route" @click="doRoute(x)">
                {{ x.title }}
                <div class="menu-panel" v-if="x.menus && x.menus?.length > 0">
                    <div class="menu-sub-item" v-for="y of x.menus" :key="y.route" @click.stop="doRoute(y)">
                        {{ y.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref} from "vue"
import { useRouter } from "vue-router";
import sitelist from "@/router/sitelist"

type MenuItem = {
    title: string;
    route: string;
    query?: Record<string, any>;
    menus?: MenuItem[];
}

const route = useRouter()

const menu_items = ref<MenuItem[]>(sitelist)

function doRoute(r: MenuItem) {
    if (r.query) {
        route.push({name: r.route, query: r.query})
    } else {
        route.push({name: r.route})
    }
} 
</script>

<style lang="scss" scoped>
.gnb-view {
    font-family: NotoSansKR;
    font-weight: bold;
    .logo {
        height: 2rem;
    }
    .menu-bar {
        padding: 0 384px;
        height: 93px;
        display: flex;
        justify-content: space-between;        
        align-items: center;
        background-color: #fff;
        // background: url("@/assets/menubar.png");
        .menu-item {
            cursor: pointer;
            // color: white;
            color: #3e3a39;
        }
        .menu-item:hover {
            text-decoration: underline; 
            .menu-panel {
                display: block;
            }
        }
        .menu-panel {
            display: none;
            position: absolute;
            border: 1px solid #69C4CC;
            border-radius: 0.3rem;
            background-color: white;
            color: black;
            .menu-sub-item {
                padding: 0.7rem;
            }
            .menu-sub-item:hover {
                text-decoration: underline; 
            }
        }
    }
}
</style>
<template>
    <div class="partner-view">
        <GNBView class="gnb" style="position: relative; z-index: 1000;"></GNBView>
        <div>
            <div style="width: 100%; display: flex; flex-direction: column; align-items: center; padding-top: 150px; margin-bottom: 64px;">
                <div style="position: relative; width: 100%; display: flex; justify-content: center;">
                    <div class="section">Partners</div>
                </div>
            </div>
            <div class="list">
                <template v-for="({category, partners}) in categoriesOrdered" :key="category">
                    <div class="category-tab">
                        <div style="font-size: 25px; font-weight: bold; color: #4d5354; margin-top: 10px;">
                            {{ category }}
                        </div>
                    </div>
                    <div class="items">
                        <PartnerViewCard
                            v-for="item of partners"
                            :key="item.no"
                            :name="item.name"
                            :no="item.no"
                            :image="item.image"
                        ></PartnerViewCard>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { Partner } from '@/comms/partner';
import GNBView from './GNBView.vue';
import PartnerViewCard from './PartnerViewCard.vue'
import comms from '@/comms/comms';

const items = ref<Partner[]>([]);
const page = ref<number>(0);
const size = ref<number>(10000);
const categories = ref<{[key: string]: Partner[]}>({});
const categoriesOrdered = ref<{category: string, partners: Partner[]}[]>([]);

async function updateList() {
    categories.value = {};
    const res = await comms.partner.list(page.value, size.value);
    if (res.status == 200 && res.payload) {
        if (res.payload.total > 0) {
            items.value = res.payload.partners;
            // items.value.sort( (x, y) => (y.year > x.year)?-1:1)
            for (const item of items.value) {
                if (categories.value[item.category]) {
                    categories.value[item.category].push(item);
                } else {
                    categories.value[item.category] = [ item ];
                }
            }

            categoriesOrdered.value = [];
            for (const key of Object.keys(categories.value).sort((x, y) => (x > y)? -1: 1)) {
                categoriesOrdered.value.push({category: key, partners: categories.value[key]});
            }
        }
    }
}

onMounted(() => {
    updateList();
});

</script>

<style lang="scss" scoped>
.partner-view {
    width: 1900px;
    box-sizing: border-box;
    font-family: NotoSansKR;
    background-color: #efefefff;
    .gnb {
        width: 100%;
        font-size: 14.5px;
    }
    .section {
        color: #235a69;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        padding: 7px 50px;
        border: 1px solid #888;
        background-color: white;
    }
    .list {
        padding: 0 384px;
        padding-bottom: 5rem;
        .category-tab {
            display: flex;
            flex-direction: column;
            align-items: left;
            width: 30%;
            color: black;
            padding: 0.5rem;
            padding-left: 1rem;
            margin-left: 1rem;
            padding-top: 2rem;
            border-bottom: 2px solid #4d5354;
            cursor: pointer;
        }
        .items {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: left;
                padding: 1rem;
            }
    }


}
</style>
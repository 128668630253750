const serviceUrl = "http://theredesignx.com"
// const serviceUrl = "";
// const serviceUrl = "http://localhost:8090";

async function postJson(path: string, body: object) {
  try {
    const req = await fetch(`${serviceUrl}${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return await req.json();
  } catch (e) {
    console.log(e);
  }
}

async function getJson(path: string): Promise<any> {
  try {
    const req = await fetch(`${serviceUrl}${path}`);
    return await req.json();
  } catch (e) {
    console.log(e);
  }
}

async function deleteJson(path: string): Promise<any> {
  try {
    const req = await fetch(path, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (req.status == 200) {
      return await req.json();
    }
  } catch (e) {
    console.log(e);
  }
}

async function patchJson(path: string, body: object) {
  try {
    const req = await fetch(`${serviceUrl}${path}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return await req.json();
  } catch (e) {
    console.log(e);
  }
}

interface ApiResponse<T> {
	status:  number
	error:   string
	payload: T
}

async function uploadFile(files: File[]): Promise<string[]> {
  const paths: string[] = [];
  for (const v of files) {
      const formData = new FormData();
      formData.append("file", v);
      const req = await fetch(
          `${serviceUrl}/api/file/upload`,
          {
              method: "POST",
              body: formData,
          }
      );
      const res = await req.json();
      console.log("res: ", res);
      paths.push(res.payload);
  }
  return paths;
}

export { postJson, getJson, serviceUrl, deleteJson, patchJson, uploadFile, ApiResponse };

import {ApiResponse, getJson, postJson} from "./utils"

interface ContactUs {
    no: number;
    name: string;
    company: string;
    job: string;
    email: string;
    phone: string;
    category: string;
    message: string;
    mailing_list: boolean;
    privacy: boolean;
    created_at: number;
    updated_at: number;
}

interface ListResponse {
    total: number;
    projects: ContactUs[];
}

class ContactUsComms {
    async create(project: ContactUs): Promise<ApiResponse<string>> {
        const req: ContactUs = {...project};
        return await postJson("/api/x/contactus/create", req);
    }

    async update(know: ContactUs): Promise<ApiResponse<string>> {
        const req: ContactUs = {...know};
        return await postJson("/api/contactus/update", req);
    }

    async list(page: number, size: number): Promise<ApiResponse<ListResponse>> {
        return await getJson(`/api/contactus/list?page=${page}&size=${size}`);
    }

    async delete(no: number): Promise<ApiResponse<string>> {
        return await getJson(`/api/contactus/delete/${no}`);
    }
}

const contactUs = new ContactUsComms();

export {
    ContactUs,
    ContactUsComms,
}

export default contactUs;

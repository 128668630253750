<template>
    <div class="one-panel">
        <slot></slot>
    </div>    
</template>

<style lang="scss" scoped>
.one-panel {
    width: 100%;
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
import MobileApp from './MobileApp.vue'
import router from './router'
import mobileRouter from './mobile_router'
import "./style/common.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice';
import 'primevue/resources/themes/lara-light-teal/theme.css';

const r = screen.width/screen.height;

console.log(`ratio: ${r}`);

if (r <= 0.5) {
    const app = createApp(MobileApp);
    app.use(mobileRouter)
    app.use(PrimeVue)
    app.use(ToastService);
    app.use(ConfirmationService);
    app.mount('#app')
} else {
    const app = createApp(App);
    app.use(router)
    app.use(PrimeVue)
    app.use(ToastService);
    app.use(ConfirmationService);
    app.mount('#app')
}

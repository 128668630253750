import knowledge, { KnowledgeComms } from "./knowledge";
import project, { Project } from "./project";
import partner, { Partner, PartnerComms } from "./partner";
import contactUs, { ContactUs } from "./contactus";

export interface Comms {
    knowledge: KnowledgeComms,
    project: Project,
    contactUs: ContactUs,
    partner: PartnerComms
}

export default {
    knowledge,
    project,
    contactUs,
    partner
}

<template>
    <div class="knowledge-view">
        <GNBView class="gnb"></GNBView>
        <div style="width: 100%; display: flex; justify-content: center; margin-top: 150px;">
            <div class="section">Knowledge</div>
        </div>
        <div class="knowledge-category">
            <div class="item" :enabled="categorySelected == 'all'" @click="categoryClick('all')">All articles</div>
            <div style="width: 1px; margin: 0 40px; padding: 20px 0; border-left: 1px solid #aaa;"></div>
            <div class="item" :enabled="categorySelected == 'report'" @click="categoryClick('report')">Report</div>
            <div style="width: 1px; margin: 0 40px; padding: 20px 0; border-left: 1px solid #aaa;"></div>
            <div class="item" :enabled="categorySelected == 'news,media'" @click="categoryClick('news,media')">News & Media</div>
            <div style="width: 1px; margin: 0 40px; padding: 20px 0; border-left: 1px solid #aaa;"></div>
            <div class="item" :enabled="categorySelected == 'knowledge'" @click="categoryClick('knowledge')">Knowledge</div>
        </div>
        <div class="list" v-if="items && items.length > 0">
            <KnowledgeViewCard
                v-for="item of items"
                :key="item.no"
                :category="item.category"
                :title="item.title"
                :image="item.image"
                :updated-at="item.updated_at"
                :summary="item.summary"
                :content="item.content"
                :no="item.no"
                @click="openContent(item.no)"
                >
            </KnowledgeViewCard>
       </div>
        <div style="margin-bottom: 3rem;"></div>
    </div>
</template>

<script setup lang="ts">
import OnePanel from '@/components/OnePanel.vue';
import GNBView from './GNBView.vue';
import TwoPanel from '@/components/TwoPanel.vue';
import ThreePanel from '@/components/ThreePanel.vue';
import FourPanel from '@/components/FourPanel.vue'
import KnowledgeViewCard from './KnowledgeViewCard.vue';
import { defineProps, onMounted, ref, watch } from 'vue';
import { Knowledge } from '@/comms/knowledge';
import comms from '@/comms/comms';
import { useRouter } from 'vue-router';

const router = useRouter();
const items = ref<Knowledge[]>([]);
const page = ref<number>(0);
const size = ref<number>(10000);
const categorySelected = ref<string>("all");
const props = defineProps({
    category: String
});

watch(props, () => {
    switch(props.category) {
        case "all":
            categorySelected.value = "all";
            break;
        case "report":
            categorySelected.value = "report";
            break;
        case "newsmedia":
            categorySelected.value = "news,media";
            break;
        case "knowledge":
            categorySelected.value = "knowledge";
            break;
    }
    updateList();
})

async function updateList() {
    const res = await comms.knowledge.list(categorySelected.value, page.value, size.value);
    if (res.status == 200 && res.payload) {
        if (res.payload.total > 0) {
            items.value = res.payload.knowledges;
        }
    }
}

function categoryClick(cat: string) {
    categorySelected.value = cat;
    updateList();
}

function openContent(no: number) {
    router.push({name: "knowledge-content", params: {no: no.toString()}})
}

onMounted(() => {
    switch(props.category) {
        case "all":
            categorySelected.value = "all";
            break;
        case "report":
            categorySelected.value = "report";
            break;
        case "newsmedia":
            categorySelected.value = "news,media";
            break;
        case "knowledge":
            categorySelected.value = "knowledge";
            break;
    }
    updateList();
})

</script>

<style lang="scss" scoped>
.vhcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hcenter {
    width: 100%;
    display: flex;
    justify-content: center;
}
.knowledge-view {
    // width: 1366px;
    width: 100%;
    box-sizing: border-box;
    font-family: NotoSansKR;
    .gnb {
        width: 100%;
        font-size: 14.5px;
    }
    .section {
        color: #235a69;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        padding: 7px 32px;
        border: 1px solid #888;
        margin-bottom: 150px;
    }
    .list {
        display: flex;
        flex-wrap: wrap;
    }
    .knowledge-category {
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;
        color: #333;
        .item {
            padding: 0 2rem;
            font-weight: bold;
            cursor: pointer;
            user-select: none;
            color: #ccc;
            &[enabled=true] {
                text-decoration: underline;
                color: #4d5354;
            }
        }
    }
}
</style>
<template>
    <div class="two-panel">
        <div class="panel">
            <slot name="one"></slot>
        </div>
        <div class="panel">
            <slot name="two"></slot>
        </div>
        <div class="panel">
            <slot name="three"></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.two-panel {
    width: 100%;
    display: flex;
    flex-shrink: 0;
    .panel {
        width: 33.33%;
        height: 100%;
    }
}
</style>
import {ApiResponse, getJson, postJson} from "./utils"

interface Project {
    no: number;
    index: number;
    title: string;
    image: string;
    year: string;
    user: string;
    startDate: number;
    endDate: number;
    orderOrg: string;
    created_at: number;
    updated_at: number;
}

interface ListResponse {
    total: number;
    projects: Project[];
}

class ProjectComms {
    async list(page: number, size: number): Promise<ApiResponse<ListResponse>> {
        return await getJson(`/api/x/project/list?page=${page}&size=${size}`);
    }
}

const project = new ProjectComms();

export {
    Project,
    ProjectComms,
}

export default project;

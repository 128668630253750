<template>
    <div class="service-view">
        <GNBView class="gnb"></GNBView>
        <div class="service-view-body">
            <div class="section1" style="padding-bottom: 64px; padding-top: 64px;">
                <div class="vcenter" style="height: 100%;">
                    <div style="font-size: 30px; font-weight: bolder; line-height: 1.75; color: #3c3c3c;">
                        <div>새로운 시선으로</div>
                        <div>현재를 다시보며</div>
                        <div>새로운 미래에 닿다.</div>
                        <div>함께하며, 다른 경험을 만들다</div>
                        <div style="display: flex; align-items: flex-end; padding-top: 43px;">
                            <img src="@/assets/redesignx.png" style="height: 128px; width: 149px;">
                            <div class="getintouch" style="margin-left: 16px;" @click="doRoute">
                                Get in touch
                            </div>
                            <!-- <img src="@/assets/getintouch.png" style="height: 42px; width: 158px"> -->
                        </div>
                    </div>
                </div>
                <div class="vcenter" style="height: 100%; margin-top: 32px; display: flex; justify-content: center;">
                    <img src="@/assets/home_innovation.png" style="max-width: 90%; max-height: 667px;">
                </div>
            </div>
            <div class="section2" style="padding: 64px 0;">
                <div class="card" style="background-color: #235a69ff;">
                    <img src="@/assets/section1_2_1.webp" style="position: absolute; top: 40px; max-width: 100%; max-height: 667px;">
                    <span style="position: absolute; bottom: 40px;">Innovation</span>
                </div>
                <div class="card" style="background-color: #e9c92dff; margin-top: 50px; line-height: 1.3;">
                    <img src="@/assets/section1_2_2.webp" style="position: absolute; top: 40px; max-width: 100%; max-height: 667px;">
                    <span style="position: absolute; bottom: 70px;">Resesign</span>
                    <span style="position: absolute; bottom: 20px; line-height: 1; font-size: 24px; color: #4c5253; font-weight: normal;  text-align: center;">Framework - Model - Analysis</span>
                </div>
                <div class="card" style="background-color: #235a69ff; margin-top: 50px; line-height: 1.3;">
                    <img src="@/assets/section1_2_3.webp" style="position: absolute; top: 40px; max-width: 100%; max-height: 667px;">
                    <span style="position: absolute; bottom: 40px;">Implications</span>
                </div>
                <div class="card"  style="background-color: #e9c92dff; margin-top: 50px; line-height: 1.3;">
                    <img src="@/assets/section1_2_4.webp" style="position: absolute; top: 40px; max-width: 100%; max-height: 667px;">
                    <span style="position: absolute; bottom: 10px; text-align: center;">Decision making</span>
                </div>
                <div style="text-align: left; font-size: 24px; color: white; font-weight: normal; padding: 0 16px; text-align: center; line-height: 1.4; margin-top: 33px;">
                    <div>Redesign X 의 프레임워크, 분석, 모델은 공공/민간의 혁신, 비즈니스, </div>
                    <div>투자 부문 주요 의사결정권자의 더 나은 정보에 기반한 결정을 지원합니다.</div>
                </div>
                <div class="left_arrow vcenter" style="color: #3b3b3b; background-color: #bbe4e8ff; margin-top: 32px">
                    <!-- <div style="width: 358px; border-bottom: 2px solid #3b3b3b; text-align: left;"> -->
                        <!-- <img src="@/assets/rdx_innovation.webp"> -->
                    <!-- </div> -->
                    <div style="font-size: 12px; text-align: left; font-weight: normal; width: 358px; padding: 16px 24px;">
                        <div style="border-bottom: 2px solid #333; padding: 8px 0;">
                            <img src="@/assets/home_rdx_innovation.webp" style="max-width: 70%; max-height: 667px;">
                        </div>
                        <div style="font-size: 20px; font-weight: bold;">ReDesign x Innovation,</div>
                        <div>현재를 넓고 깊게 다시봅니다. 미래의 새로운 혁신을 위해,</div>
                        <div>현재의 가지고 있는 다양한 가치를 깊이있게 다시보고</div>
                        <div>협력과 결합을 통해 재설계 합니다.</div>
                    </div>
                </div>
                <div class="right_arrow vcenter" style="color: #3b3b3b; background-color: #bbe4e8ff; margin-top: 32px">
                    <!-- <div style="width: 358px; border-bottom: 2px solid #3b3b3b; text-align: left;"> -->
                        <!-- <img src="@/assets/rdx_earth.webp"> -->
                    <!-- </div> -->
                    <div style="font-size: 12px; text-align: left; font-weight: normal; width: 358px; padding: 16px 24px;">
                        <div style="border-bottom: 2px solid #333; padding: 8px 0;">
                            <img src="@/assets/home_rdx_earth.webp" style="max-width: 50%; max-height: 667px;">
                        </div>
                        <div style="font-size: 20px; font-weight: bold;">ReDesign x earth,</div>
                        <div>측정을 통해 변화를 만듭니다. 정부와 기업, 투자자가</div>
                        <div>기후행동 참여를 촉진하기 위한 과학적인, 데이터 기반의</div>
                        <div>측정과 예측을 지원합니다.</div>
                    </div>
                </div>
            </div>
            <div class="section3" style="padding-bottom: 64px;">
                <OnePanel class="area3" style="color: #235a69; line-height: 1.33; ">
                    <div style="width: 200px; font-size: 30px; display:flex; justify-content: space-between; margin-bottom: 21px; margin-top: 25px;"><div>주</div><div>요</div><div>서</div><div>비</div><div>스</div></div>
                    <img src="@/assets/home_spliter_01.png" style="padding: 64px 0;">
                </OnePanel>
                <div style="height: 100%; display: flex; justify-content: flex-start; align-items: flex-end; padding: 0 32px;">
                    <img src="@/assets/home_innovation_image_1.png" style="max-width: 100%;">
                </div>
                <div class="vcenter" style="height: 100%; justify-content: flex-end; padding: 0 32px; padding-top: 32px;">
                    <div>
                        <div class="card-title">
                            Policy Research + R&D Strategy
                        </div>
                        <div class="card-body">
                            <div>· R&I (Research & Innovation) 의제 발굴 및 정책 연구</div>
                            <div>· R&I (Research & Innovation) 프로그램 기획 및 전략 수립</div>
                            <div>· R&D 프로젝트 기획 및 기술개발 전략 수립</div>
                            <div>· 신사업 발굴 및 사업개발</div>
                            <div>· 산업, 시장, 기술 동향 분석</div>
                            <div>· 신기술 특허 분석 및 전략 수립</div>
                            <div>· 경제적, 사회적, 환경적 가치/효과 분석 및 타당성 조사</div>
                        </div>
                    </div>
                </div>
                <OnePanel class="area3" style="color: #235a69; line-height: 1.33;">
                    <img src="@/assets/home_spliter_02.png" style="padding: 100px 0">
                </OnePanel>
                <div style="height: 100%; padding: 0 32px;">
                    <img src="@/assets/home_innovation_image_2.png" style="max-width: 100%;">
                </div>
                <div class="vcenter" style="height: 100%; justify-content: flex-end; padding: 0 32px; padding-top: 32px;">
                    <div>
                        <div class="card-title">
                            온실가스 배출량 산정 및 감축효과 평가
                        </div>
                        <div class="card-body">
                            <div>· 정부, 기관, 지자체 온실가스 저감 정책 및 감축효과 평가</div>
                            <div>· 탄소중립을 위한 로드맵 및 세부 실행전략 수립</div>
                            <div>· 기업 탄소 배출량 산정(Scope 1/2/3) 및 탄소중립 전략 수립</div>
                            <div>· 글로벌 표준(GHG Protocol, Carbon Call) 기반 탄소회계</div>
                            <div>· 외부사업 및 자발적 감축사업의 배출량 및 감축량 평가</div>
                            <div>· Net Zero를 위한 자발적 참여기반 이니셔티브 설계</div>
                            <div>· Carbon accounting solution (PCRAS tool)</div>
                        </div>
                    </div>
                </div>
                <div class="vcenter" style="height: 100%; justify-content: flex-end; padding: 0 32px; padding-top: 32px;">
                    <img src="@/assets/home_innovation_image_3.png" style="max-width: 100%; max-height: 315px;">
                </div>
                <div class="vcenter" style="height: 100%; justify-content: flex-start; padding: 0 32px; padding-top: 32px;">
                    <div>
                        <div class="card-title">
                            사회적 경제조직의 사회가치평가
                        </div>
                        <div class="card-body">
                            <div>· 사회적기업, 협동조합 등의 사회적 가치 평가</div>
                            <div>· SPC 기반 인센티브 사회적 성과 측정</div>
                            <div>· 사회적 가치 측정 및 시스템 구축</div>
                            <div>· 임팩트 투자 의사결정 및 투자 이후의 성과 평가</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import OnePanel from '@/components/OnePanel.vue';
import GNBView from './GNBView.vue';
import TwoPanel from '@/components/TwoPanel.vue';
import { useRouter } from "vue-router";

const route = useRouter()

function doRoute() {
    route.push({name: 'contactus'})
}
</script>

<style lang="scss" scoped>
.vhcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vcenter {
    display: flex;
    align-items: center;
}
.hcenter {
    width: 100%;
    display: flex;
    justify-content: center;
}
.service-view {
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: NotoSansKR;
    overflow: hidden;

    .service-view-body {
        width: 100%;
    }
    .gnb {
        width: 100%;
        font-size: 14.5px;
    }
    .section1 {
        width: 100%;
        padding: 0 16px;
        padding-top: 32px;
        background-image: url("@/assets/background1.webp");
        .getintouch {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            width: 158px;
            background: url('@/assets/getintouch.png');
            font-size: 17px;
            font-family: 'OpenSans';
            color: white;
            cursor: pointer;
        }
    }
    .section2 {
        width: 100%;
        background-color: #69c4ccff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .card {
            position: relative;
            width: 275px;
            height: 235px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 40px;
            font-weight: bold;
            background-color: #800;
        }

        // background-image: url("@/assets/section2_background.webp");

        .card-title {
            font-size: 40px;
            padding-bottom: 20px;
        }
        .card-body {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .section3 {
        width: 100%;
        background-color: #fdfaeaff;
        display: flex;
        flex-direction: column;
        align-items: center;

        .card {
            position: relative;
            width: 275px;
            height: 235px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 40px;
            font-weight: bold;
            background-color: #800;
        }

        // background-image: url("@/assets/section2_background.webp");

        .card-title {
            font-size: 30px;
            padding-bottom: 20px;
            font-weight: bolder;
        }
        .card-body {
            font-size: 17px;
            font-weight: 500;
        }
    }    
    .area3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-weight: bold;
        box-sizing: border-box;
    }
}
</style>
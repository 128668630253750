<template>
    <div class="project-view">
        <GNBView class="gnb"></GNBView>
        <div style="background-color: #efefefff">
            <div style="width: 100%; display: flex; flex-direction: column; align-items: center; padding-top: 150px; margin-bottom: 64px;">
                <div class="section">Project</div>
                <div style="color: #4d5354; font-size: 30px; font-weight: bold; font-family: NotoSansKR; text-align: center; margin-top: 80px;">
                    <div>우리가 하는</div>
                    <div>프로젝트</div>
                </div>
            </div>
            <div class="header">
                <div>온실 가스 프로젝트는 온실 가스 배출을 감소하거나 지구 온난화와 기후 변화에 대한</div>
                <div>대응을 강화하기 위한 일련의 노력과 활동을 포함하는 프로젝트입니다.</div>
                <div>이러한 프로젝트는 환경 보호와 지구 온난화에 대한 글로벌 대응을 강조하며, 다양한 분야에서 진행됩니다.</div>
                <div>아래는 온실 가스 프로젝트의 일반적인 내용과 목표에 대한 몇 가지 예시입니다.</div>
            </div>
            <div class="list">
                <template v-for="({year, projects}) in yearsOrdered" :key="year">
                    <div class="year-tab">
                        <div style="width: 20px; height: 20px; border-radius: 15px; background-color: #235a69ff;">
                        </div>
                        <div style="font-size: 25px; font-weight: bold; color: #4d5354; margin-top: 10px;">
                            {{ year }}
                        </div>
                    </div>
                    <div class="items">
                        <ProjectViewCard
                            v-for="item of projects"
                            :key="item.no"
                            :title="item.title"
                            :no="item.no"
                            :image="item.image"
                            :startDate="item.startDate"
                            :endDate="item.endDate"
                            :orderOrg="item.orderOrg"
                        ></ProjectViewCard>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import GNBView from './GNBView.vue';
import { onMounted, ref } from 'vue';
import { Project } from '@/comms/project';
import ProjectViewCard from './ProjectViewCard.vue'
import comms from '@/comms/comms';

const items = ref<Project[]>([]);
const page = ref<number>(0);
const size = ref<number>(10000);
const years = ref<{[key: string]: Project[]}>({});
const yearsOrdered = ref<{year: string, projects: Project[]}[]>([]);

async function updateList() {
    years.value = {};
    const res = await comms.project.list(page.value, size.value);
    if (res.status == 200 && res.payload) {
        if (res.payload.total > 0) {
            items.value = res.payload.projects;
            // items.value.sort( (x, y) => (y.year > x.year)?-1:1)
            for (const item of items.value) {
                if (years.value[item.year]) {
                    years.value[item.year].push(item);
                } else {
                    years.value[item.year] = [ item ];
                }
            }

            yearsOrdered.value = [];
            for (const key of Object.keys(years.value).sort((x, y) => (x > y)? -1: 1)) {
                yearsOrdered.value.push({year: key, projects: years.value[key]});
            }
        }
    }
}

onMounted(() => {
    updateList();
});

</script>

<style scoped lang="scss">
.project-view {
    width: 1900px;
    box-sizing: border-box;
    font-family: NotoSansKR;
    .gnb {
        width: 100%;
        font-size: 14.5px;
    }
    .section {
        color: #235a69;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        padding: 7px 50px;
        border: 1px solid #888;
        background-color: white;
    }
    .header {
        width: 100%;
        text-align: center;
        padding: 0 384px;
        font-size: 20px;
        margin-bottom: 2rem;
        font-weight: 600;
        color: #4d5354;
    }
    .list {
        padding: 0 384px;
        .year-tab {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            color: black;
            padding: 0.5rem;
            cursor: pointer;
            .btn {
                padding: 0 0.7rem;
            }
        }
        .items {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 1rem;
            }
    }


}
</style>
<template>
    <div class="service-view">
        <GNBView class="gnb"></GNBView>
        <div class="service-view-body">
            <div class="section1">
                <TwoPanel class="area1" style="padding-top: 37px;">
                    <template v-slot:one>
                        <div class="vcenter" style="height: 100%; padding-right: 50px;">
                            <div style="font-size: 35px; font-weight: bolder; line-height: 1.75; color: #3c3c3c;">
                                <div>새로운 시선으로</div>
                                <div>현재를 다시보며</div>
                                <div>새로운 미래에 닿다.</div>
                                <div>함께하며, 다른 경험을 만들다</div>
                                <div style="display: flex; align-items: flex-end; justify-content: space-between; padding-top: 43px;">
                                    <img src="@/assets/redesignx.png" style="height: 183px; width: 213px;">
                                    <div class="getintouch" @click="doRoute">
                                        Get in touch
                                    </div>
                                    <!-- <img src="@/assets/getintouch.png" style="height: 42px; width: 158px"> -->
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:two>
                        <div class="vcenter" style="height: 100%;">
                            <img src="@/assets/home_innovation.png" style="max-width: 544px; max-height: 667px;">
                        </div>
                    </template>
                </TwoPanel>
                <OnePanel class="area2">
                    <div class="cardboard">
                        <div class="card">
                            <span>Innovation</span>
                        </div>
                        <div class="card" style="padding-top: 235px; line-height: 1.3;">
                            <span>Resesign</span>
                            <span style="font-size: 24px; color: #4c5253; font-weight: normal; line-height: 1;">Framework - Model - Analysis</span>
                        </div>
                        <div class="card">
                            <span>Implications</span>
                        </div>
                        <div class="card" style="padding-top: 260px; line-height: 1;">
                            <span>Decision making</span>
                        </div>
                    </div>
                    <div style="text-align: left; font-size: 24px; color: white; font-weight: normal; padding: 0 200px; text-align: center; line-height: 1.4; margin-top: 33px;">
                        <div>Redesign X 의 프레임워크, 분석, 모델은 공공/민간의 혁신, 비즈니스, </div>
                        <div>투자 부문 주요 의사결정권자의 더 나은 정보에 기반한 결정을 지원합니다.</div>
                    </div>
                    <div style="display: flex; justify-content: center; margin-top: 68px;">
                        <div class="left_arrow vcenter" style="color: #3b3b3b">
                            <!-- <div style="width: 358px; border-bottom: 2px solid #3b3b3b; text-align: left;"> -->
                                <!-- <img src="@/assets/rdx_innovation.webp"> -->
                            <!-- </div> -->
                            <div style="font-size: 15px; text-align: left; font-weight: normal; width: 358px;">
                                <div style="font-size: 20px; font-weight: bold;">ReDesign x Innovation,</div>
                                <div>현재를 넓고 깊게 다시봅니다. 미래의 새로운 혁신을 위해,</div>
                                <div>현재의 가지고 있는 다양한 가치를 깊이있게 다시보고</div>
                                <div>협력과 결합을 통해 재설계 합니다.</div>
                            </div>
                        </div>
                        <div class="right_arrow vcenter">
                            <!-- <div style="width: 358px; border-bottom: 2px solid #3b3b3b; text-align: left;"> -->
                                <!-- <img src="@/assets/rdx_earth.webp"> -->
                            <!-- </div> -->
                            <div style="font-size: 15px; text-align: left; font-weight: normal; width: 358px;">
                                <div style="font-size: 20px; font-weight: bold;">ReDesign x earth,</div>
                                <div>측정을 통해 변화를 만듭니다. 정부와 기업, 투자자가</div>
                                <div>기후행동 참여를 촉진하기 위한 과학적인, 데이터 기반의</div>
                                <div>측정과 예측을 지원합니다.</div>
                            </div>
                        </div>
                    </div>
                </OnePanel>
            </div>
            <div class="section2">
                <OnePanel class="area3" style="color: #235a69; line-height: 1.33; ">
                    <div style="width: 200px; font-size: 30px; display:flex; justify-content: space-between; margin-bottom: 21px; margin-top: 25px;"><div>주</div><div>요</div><div>서</div><div>비</div><div>스</div></div>
                    <img src="@/assets/home_spliter_01.png" style="padding: 64px 0;">
                </OnePanel>
                <TwoPanel class="work-area">
                    <template v-slot:one>
                        <div class="vcenter" style="height: 100%; justify-content: flex-end; padding: 0 32px;">
                            <div>
                                <div class="card-title">
                                    Policy Research + R&D Strategy
                                </div>
                                <div class="card-body">
                                    <div>· R&I (Research & Innovation) 의제 발굴 및 정책 연구</div>
                                    <div>· R&I (Research & Innovation) 프로그램 기획 및 전략 수립</div>
                                    <div>· R&D 프로젝트 기획 및 기술개발 전략 수립</div>
                                    <div>· 신사업 발굴 및 사업개발</div>
                                    <div>· 산업, 시장, 기술 동향 분석</div>
                                    <div>· 신기술 특허 분석 및 전략 수립</div>
                                    <div>· 경제적, 사회적, 환경적 가치/효과 분석 및 타당성 조사</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:two>
                        <div style="height: 100%; display: flex; justify-content: flex-start; align-items: flex-end; padding: 0 32px;">
                            <img src="@/assets/home_innovation_image_1.png">
                        </div>
                    </template>
                </TwoPanel>
                <OnePanel class="area3" style="color: #235a69; line-height: 1.33;">
                    <img src="@/assets/home_spliter_02.png" style="padding: 100px 0">
                </OnePanel>
                <TwoPanel class="work-area">
                    <template v-slot:one>
                        <div class="vcenter" style="height: 100%; justify-content: flex-end; padding: 0 32px;">
                            <div>
                                <div class="card-title">
                                    온실가스 배출량 산정 및 감축효과 평가
                                </div>
                                <div class="card-body">
                                    <div>· 정부, 기관, 지자체 온실가스 저감 정책 및 감축효과 평가</div>
                                    <div>· 탄소중립을 위한 로드맵 및 세부 실행전략 수립</div>
                                    <div>· 기업 탄소 배출량 산정(Scope 1/2/3) 및 탄소중립 전략 수립</div>
                                    <div>· 글로벌 표준(GHG Protocol, Carbon Call) 기반 탄소회계</div>
                                    <div>· 외부사업 및 자발적 감축사업의 배출량 및 감축량 평가</div>
                                    <div>· Net Zero를 위한 자발적 참여기반 이니셔티브 설계</div>
                                    <div>· Carbon accounting solution (PCRAS tool)</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-slot:two>
                        <div style="height: 100%; padding: 0 32px;">
                            <img src="@/assets/home_innovation_image_2.png">
                        </div>
                    </template>
                </TwoPanel>
                <TwoPanel class="work-area" style="padding-bottom: 200px;">
                    <template v-slot:one>
                        <div class="vcenter" style="height: 100%; justify-content: flex-end; padding: 0 32px;">
                            <img src="@/assets/home_innovation_image_3.png" style="max-width: 667px; max-height: 315px;">
                        </div>
                    </template>
                    <template v-slot:two>
                        <div class="vcenter" style="height: 100%; justify-content: flex-start; padding: 0 32px;">
                            <div>
                                <div class="card-title">
                                    사회적 경제조직의 사회가치평가
                                </div>
                                <div class="card-body">
                                    <div>· 사회적기업, 협동조합 등의 사회적 가치 평가</div>
                                    <div>· SPC 기반 인센티브 사회적 성과 측정</div>
                                    <div>· 사회적 가치 측정 및 시스템 구축</div>
                                    <div>· 임팩트 투자 의사결정 및 투자 이후의 성과 평가</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </TwoPanel>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import OnePanel from '@/components/OnePanel.vue';
import GNBView from './GNBView.vue';
import TwoPanel from '@/components/TwoPanel.vue';
import InnoWorkCard from '@/views/InnoWorkCard.vue';
import { useRouter } from "vue-router";

const route = useRouter()

function doRoute() {
    route.push({name: 'contactus'})
}
</script>

<style lang="scss" scoped>
.vhcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vcenter {
    display: flex;
    align-items: center;
}
.hcenter {
    width: 100%;
    display: flex;
    justify-content: center;
}
.service-view {
    width: 1900px;
    overflow-x: hidden;
    box-sizing: border-box;
    font-family: NotoSansKR;

    .service-view-body {
        width: 1900px;
    }
    .gnb {
        width: 100%;
        font-size: 14.5px;
    }
    .section1 {
        width: 1900px;
        height: 1624px;
        background-image: url("@/assets/background1.webp");
        .getintouch {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 42px;
            width: 158px;
            background: url('@/assets/getintouch.png');
            font-size: 17px;
            font-family: 'OpenSans';
            color: white;
            cursor: pointer;
        }
    }
    .section2 {
        width: 1900px;
        height: 2232px;
        background-color: #fdfaeaff;
        // background-image: url("@/assets/section2_background.webp");

        .card-title {
            font-size: 40px;
            padding-bottom: 20px;
        }
        .card-body {
            font-size: 20px;
            font-weight: 500;
        }
    }
    .area1 {
        width: 100%;
        padding: 0 384px;
        padding-top: 2px;
        background-position: center;
        margin-bottom: 150px;
    }
    .area2 {
        .cardboard {
            width: 1147px;
            height: 338px;
            display: flex;
            justify-content: space-between;
            transform: translateY(-52px);
            // background: center url("@/assets/section1_2_cardpanel.webp") no-repeat;
            .card {
                width: 280px;
                height: 280px;
                padding-top: 190px;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: white;
                font-size: 40px;
            }
        }
        .left_arrow {
            margin-left: 5px;
            padding-top: 55px;
            padding-left: 83px;
            width: 540px;
            height: 227px;
            // background: center url("@/assets/section1_colored_arrow.webp") no-repeat;
            z-index: 2;
            line-height: 1.5;
            color: #3c3c3c;
            font-size: 20px;
            flex-shrink: 0;
        }
        .right_arrow {
            width: 540px;
            height: 227px;
            margin-left: 59px;
            padding-top: 55px;
            padding-left: 100px;
            line-height: 1.5;
            // background: center url("@/assets/section1_mild_arrow.webp") no-repeat;
            flex-shrink: 0;
        }

        width: 100%;
        height: 768px;
        padding: 0 384px;
        background: center url("@/assets/main-process.png") no-repeat;
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        line-height: 2;
        box-sizing: border-box;
    }
    .area3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-weight: bold;
        box-sizing: border-box;
    }
    .work-area {
        width: 100%;
        height: 450px;
        font-size: 1.7rem;
        font-weight: bolder;
        .title {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }
        .description {
            font-size: 1.3rem;
            font-weight: normal;
        }
    }
    .dummy {
        width: 5rem;
        height: 5rem;
        background-color: grey;
    }
}
</style>
import {ApiResponse, getJson, postJson} from "./utils"

interface Knowledge {
    no: number;
    index: number;
    title: string;
    image: string;
    category: string;
    summary: string;
    content: string;
    user: string;
    created_at: number;
    updated_at: number;
}

interface ListResponse {
    total: number;
    knowledges: Knowledge[];
}

class KnowledgeComms {
    async list(category: string, page: number, size: number): Promise<ApiResponse<ListResponse>> {
        return await getJson(`/api/x/knowledge/list?page=${page}&size=${size}&category=${category}`);
    }

    async get(no: number): Promise<ApiResponse<Knowledge>> {
        return await getJson(`/api/x/knowledge/get/${no}`);
    }
}

const knowledge = new KnowledgeComms();

export {
    Knowledge,
    KnowledgeComms,
}

export default knowledge;

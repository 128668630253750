<template>
    <div class="service-view">
        <GNBView class="gnb"></GNBView>
        <OnePanel class="work-area">
            <div style="width: 100%; height: 100%; margin-top: 100px;">
                <img src="@/assets/earth-our-solution.png" style="width: 100%;">
            </div>
            <div class="hcenter" style="width: 100%; height: 100%; margin-top: 32px;">
                <div style="text-align: center; height: 70%; font-size: 1.5rem;">
                    <div style="font-size: 45px">About PCRAS</div>
                    <div style="font-size: 15px; margin-bottom: 1rem;">(Potential Carbon Reduction Assessment Standard)</div>
                    <div class="description" style="margin-top: 32px;">
                        <div>탄소중립을 실현하는 정부, 기업, 지자체에서 스스로</div>
                        <div>현재의 탄소 배출량과 미래의 감축효과를 산정할 수</div>
                        <div>있도록 지원하기 위해 ReDesignX는</div>
                        <div>웹기반 탄소 회계 솔루션인 PCRAS를 제공합니다.</div>
                    </div>
                </div>
            </div>
            <div class="hcenter">
                <div class="arrow-button" style="margin: 50px 0;" @click="doOpen">
                    RDX PCRAS 바로 가기
                </div>
            </div>
        </OnePanel>
        <OnePanel class="features" style="padding: 64px 32px">
            <div class="hcenter" style="padding-bottom: 64px;">
                <div class="section">Features</div>
            </div>
            <div>
                <InnoWorkCard
                    no="1"
                    title="간편한 탄소 회계<br>(Carbon accounting)"
                    description="파일 업로드, API, AI 기반 스마트 업로드를<br>통해 데이터 수집 및 검증을 간소화하고, GHG<br>Protocol 가이드라인에 따른 주요 지표와<br>산정 방법을 통해 정확한 탄소 배출량<br>(Carbon footprint)을 산정할 수 있습니다."
                >
                </InnoWorkCard>
            </div>
            <div style="margin-top: 32px">
                <img src="@/assets/pcras-new-1.png" style="width: 100%">
            </div>
            <div>
                <InnoWorkCard
                    no="2"
                    title="탄소 감축 전략 로드맵 수립"
                    description="도입 가능한 기술, 수단, 정책을 선택하여 해당<br>방법을 통해 미래에 어느 정도의 비용으로 어느<br>정도의 감축효과(ERP: Emission Reduction<br>Potential)를 기대할 수 있는지 측정합니다.<br>다양한 옵션을 구성·변경하여 감축 시나리오를<br>시뮬레이션하고, 여러 시나리오의 비교를 통해<br>최적의 전략 로드맵 도출이 가능합니다."
                >
                </InnoWorkCard>
            </div>
            <div style="margin-top: 32px">
                <img src="@/assets/pcras-new-2.png" style="width: 100%;">
            </div>
            <div>
                <InnoWorkCard
                    no="3"
                    title="직관적인 탄소 감축 모니터링"
                    description="과거 Carbon footprint를 추적하고 미래 탄소<br>로드맵을 직관적으로 확인할 수 있습니다.<br>동종 업계나 해당 지역에서의 배출량을<br>벤치마킹하고 감축 성과를 비교할 수 있습니다."
                >
                </InnoWorkCard>
            </div>
            <div style="margin-top: 32px">
                <img src="@/assets/pcras-new-3.png" style="width: 100%;">
            </div>
        </OnePanel>
    </div>
</template>

<script setup lang="ts">
import OnePanel from '@/components/OnePanel.vue';
import GNBView from './GNBView.vue';
import TwoPanel from '@/components/TwoPanel.vue';
import InnoWorkCard from '@/mobile_views/InnoWorkCard.vue';

function doOpen() {
    window.open("http://pcras.theredesignx.com");
}
</script>

<style lang="scss" scoped>
.vhcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hcenter {
    width: 100%;
    display: flex;
    justify-content: center;
}
.service-view {
    // width: 1366px;
    width: 100%;
    box-sizing: border-box;
    font-family: NotoSansKR;
    .gnb {
        width: 100%;
        font-size: 14.5px;
    }
    .area1 {
        width: 100%;
        padding: 0 384px;
    }
    .area2 {
        width: 100%;
        padding: 1rem 384px;
        background-color: #DBEEE8;
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        line-height: 2;
        box-sizing: border-box;
    }
    .area3 {
        width: 100%;
        padding: 1rem 384px;
        font-weight: bold;
        font-size: 1.2rem;
        text-align: center;
        line-height: 2;
        box-sizing: border-box;
    }
    .features {
        width: 100%;
        background-color: #ccc;
    }
    .work-area {
        width: 100%;
        font-size: 1.7rem;
        font-weight: bolder;
        padding: 0 16px;
        .highlight {
            font-size: 1.1rem;
            margin-bottom: 1rem;
            text-align: left;
        }
        .title {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }
        .description {
            font-size: 19px;
            font-weight: normal;
            line-height: 1.33;
            color: #124f62;
            font-family: OpenSans;
        }
        .arrow-button {
            background: url("@/assets/arrow-button.png");
            width: 208px;
            height: 42px;
            font-size: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            cursor: pointer;
        }
    }
    .section {
        width: 203px;
        height: 65px;
        background-color: white;
        font-size: 35px;
        font-weight: bold;
        border: 1px solid #aaa;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
<template>
    <div class="gnb-view">
        <div class="menu-bar">
            <img class="logo" src="@/assets/logo.png" style="cursor: pointer;" @click="doRoute({title: '', route:'home'})">
            <div class="fa-solid" :class="(menuOpened)?'fa-xmark':'fa-bars'" style="font-size: 20px; cursor: pointer;" @click="doToggleMenu"></div>
        </div>
        <div class="menu-list" v-if="menuOpened">
            <div class="menu-item" v-for="x of menu_items" :key="x.route">
                <div class="menu-title" @click="doToggleMenuGroup(x)">
                    {{ x.title }}
                    <div class="fa-solid" :class="(selectedMenuGroup == x)?'fa-chevron-up':'fa-chevron-down'" style="font-size: 12px; cursor: pointer; color: gray"></div>
                </div>
                <div class="menu-panel" :actived="selectedMenuGroup == x" >
                    <div class="menu-sub-item" v-for="y of x.menus" :key="y.route" @click="doRoute(y)">
                        {{ y.title }}
                    </div>
                </div>
            </div>
            <div class="menu-item"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {ref} from "vue"
import { useRouter } from "vue-router";
import sitelist from "@/mobile_router/sitelist"

type MenuItem = {
    title: string;
    route: string;
    query?: Record<string, any>;
    menus?: MenuItem[];
}

const menuOpened = ref<boolean>(false);

const selectedMenuGroup = ref<MenuItem | null>(null);

const route = useRouter()

const menu_items = ref<MenuItem[]>(sitelist)

function doToggleMenu() {
    menuOpened.value = !menuOpened.value;
}

function doToggleMenuGroup(x: MenuItem) {
    if (selectedMenuGroup.value == x) {
        selectedMenuGroup.value = null;
    } else {
        selectedMenuGroup.value = x;
    }
}

function doRoute(r: MenuItem) {
    if (r.query) {
        route.push({name: r.route, query: r.query})
    } else {
        route.push({name: r.route})
    }
} 
</script>

<style lang="scss" scoped>
.gnb-view {
    font-family: NotoSansKR;
    font-weight: bold;
    .logo {
        height: 2rem;
    }

    .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 32px;

        .menu-item {
            width: 100%;
            padding: 16px 0;
            border-top: 1px solid #ddd;

            .menu-title {
                font-size: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                // color: white;
                color: #3e3a39;
            }
            .menu-panel {
                display: none;
                color: black;
                .menu-sub-item {
                    padding: 0.7rem;
                }
            }
            .menu-panel[actived=true] {
                display: block;
            }

        }
    }

    .menu-bar {
        height: 93px;
        width: 100%;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;        
        align-items: center;
        background-color: #fff;
        // background: url("@/assets/menubar.png");
    }
}
</style>
<template>
    <div class="footer-view">
        <div class="content-area">
            <div class="logo-area" style="width: 100%">
                <img src="@/assets/logo.png" style="height: 3rem">
            </div>
            <div>
                <div class="hash-area">
                    <span class="hash">#mobility</span>
                    <span class="hash">#net-zero</span>
                    <span class="hash">#sell-driving</span>
                    <span class="hash">#maas</span>
                    <span class="hash">#maritime</span>
                </div>
                <div class="site-map">
                    <div v-for="m of menus" :key="m.route">
                        <div class="top-menu">{{ m.title }}</div>
                        <div class="sub-menu" v-for="s of m.menus" :key="s.route">{{ s.title }}</div>
                    </div>
                </div>

            </div>
            <div style="width: 10%; margin-left: 0.7rem; display: flex;">
                <div class="fa-brands fa-facebook-f"></div>
                <div class="fa-brands fa-instagram"></div>
                <div class="fa-brands fa-youtube"></div>
                <div class="fa-brands fa-blogger"></div>
            </div>
            <div>

            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import sitelist from "@/mobile_router/sitelist"

const menus = ref(sitelist)

</script>

<style lang="scss" scoped>
.footer-view {
    width: 100%;
    background-color: #4D5355;
    padding: 1rem;
    box-sizing: border-box;
    font-family: OpenSans;
    .content-area {
        .logo-area {
            padding: 16px;
        }
        .hash-area {
            border-bottom: 1px solid #808080;
            .hash {
                font-weight: lighter;
                font-size: 1.2rem;
                color: #999;
                padding: 0.5rem 1rem;
            }
        }
        .site-map {
            margin-top: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid #999;
            
            .top-menu {
                color: #E6D541;
                font-weight: bold;
                font-size: 14.5px;
                margin-bottom: 0.5rem;
                margin-top: 32px;
            }
            .sub-menu {
                color: #FFFFFF;
                font-weight: normal;
                font-size: 14.5px;
                cursor: pointer;
            }
            .sub-menu:hover {
                text-decoration: underline;
            }
        }
        .fa-brands {
            border: 1px solid white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 0;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            font-size: 1.4rem;
            color: white;
            margin: 8px;
            padding: 0;
            box-sizing: border-box;
            cursor: pointer;
        }
        .fa-brands:hover {
            border-width: 2px;
        }
    }
}
</style>
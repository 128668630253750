<template>
    <div class="knowledge-content-view">
        <GNBView class="gnb"></GNBView>
        <div style="display: flex; justify-content: center;">
            <div class="contents-area">
                <div class="back-button" @click="backToList"><span class="fa fa-arrow-left"></span><span style="margin-left: 0.5rem;">Back to List</span></div>
                <div style="margin-bottom: 1rem;"></div>
                <div class="title" style="padding-bottom: 2rem;">
                    {{ content?.title }}
                </div>
                <div style="border-bottom: 1px solid #888; padding-bottom: 1rem; margin-bottom: 2rem; font-size: 1.2rem;">
                    <span>Published: </span><span>{{ toDate(content?.created_at || 0) }}</span>
                </div>
                <div style="width: 100%; max-width: 1128px;" v-html="content?.content">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import GNBView from './GNBView.vue';
import comms from '@/comms/comms';
import { Knowledge } from '@/comms/knowledge';
import { defineProps, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
let content = ref<Knowledge>()
const props = defineProps({
    no: String
});

function toDate(time: number) {
    const t = new Date(time);
    return `${t.getFullYear()}/${t.getMonth()+1}/${t.getDate()}`;
}

function backToList() {
    router.back();
}

onMounted(async () => {
    if (props.no) {
        const res = await comms.knowledge.get(parseInt(props.no));
        if (res.status == 200) {
            content.value = res.payload;
        }
    }
})

</script>

<style lang="scss" scoped>
.knowledge-content-view {
    width: 1900px;
    box-sizing: border-box;
    font-family: NotoSansKR;
    .gnb {
        font-size: 14.5px;
    }
}
</style>

<style lang="scss">
.contents-area {
    position: relative;
    width: 1128px;
    box-sizing: border-box;
    font-family: NotoSansKR;
    .back-button {
        margin-top: 5rem;
        margin-bottom: 3rem;
        font-size: 1rem;
        font-weight: bold;
        cursor: pointer;
    }

    .title {
        font-size: 3rem;
        font-weight: bold;
    }

    img {
        max-width: 100%;
    }
}
</style>
<template>
    <div class="service-view">
        <GNBView class="gnb"></GNBView>
        <div class="content">
            <div class="section">Contact us</div>
            <div class="description" style="margin: 3rem 0;">
                <div>아래 양식을 작성해서 보내주시면, 연락을 드리겠습니다.</div>
            </div>
            <div class="form">
                <div class="row">
                    <div style="width: 100%;">
                        <div>* 이름</div>
                        <InputText id="first-name" type="text" v-model="name" style="width:100%;"></InputText>
                    </div>
                </div>
                <div class="row">
                    <div style="width: 49%;">
                        <div>회사</div>
                        <InputText id="first-name" type="text" v-model="company" style="width:100%"></InputText>
                    </div>
                    <div style="width: 49%;">
                        <div>직무</div>
                        <InputText id="first-name" type="text" v-model="jobrole" style="width:100%"></InputText>
                    </div>
                </div>
                <div class="row">
                    <div style="width: 100%;">
                        <div>* 전화번호</div>
                        <InputText id="first-name" type="text" v-model="phone" style="width:100%"></InputText>
                    </div>
                </div>
                <div class="row">
                    <div style="width: 100%;">
                        <div>* 이메일 주소</div>
                        <InputText id="first-name" type="text" v-model="email" style="width:100%"></InputText>
                    </div>
                </div>
                <!-- <div class="row">
                    <div style="width: 100%;">
                        <div>어떤 분야에 관심이 있으신가요?</div>
                        <Dropdown v-model="selectedSituation" :options="situations" option-label="label" style="width:100%"></Dropdown>
                    </div>
                </div> -->
                <div class="row">
                    <div style="width: 100%;">
                        <div>메시지</div>
                        <Textarea v-model="message" style="width: 100%; height: 10rem; resize: none;" />
                    </div>
                </div>
                <div class="row">
                    <div class="vcenter">
                        <Checkbox v-model="mailingLink" binary value="mailing" />
                        <div style="margin-left: 0.5rem;">ReDesignX 에서 발행하는 시장 동향, 리포트 등의 메일을 받겠습니다.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="vcenter">
                        <Checkbox v-model="acceptingTerm" binary value="accent-term" />
                        <div style="margin-left: 0.5rem;">개인정보 처리방침 및 약관에 동의 합니다.</div>
                    </div>
                </div>
                <div class="row" style="justify-content: flex-end;">
                    <Button @click="doApply">신청<i class="fa-solid fa-arrow-right" style="padding-left: 1rem;"></i></Button>
                </div>
            </div>
        </div>
        <div style="margin-bottom: 3rem;"></div>
    </div>
</template>

<script setup lang="ts">
import GNBView from './GNBView.vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import { ContactUs } from '@/comms/contactus'
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import { ref } from 'vue';
import comms from '@/comms/comms';

// const situations = ref<{[key: string]:string}[]>([
//     {"label": "I'm looking to purchase carbon credits", "value": "purchase"},
//     {"label": "I'm looking to sell carbon credits", "value":"sell"},
//     {"label": "I'm interested in investing in projects", "value":"invest"},
//     {"label": "I'm looking to partner with reDesignX", "value":"partner"},
//     {"label": "Other(none of the above)", "value":"other"}
// ])
const name = ref<string>();
const company = ref<string>();
const jobrole = ref<string>();
const email = ref<string>();
const phone = ref<string>();
const selectedSituation = ref<{label: string, value: string}>();
const message = ref<string>();
const mailingLink = ref<boolean>(false);
const acceptingTerm = ref<boolean>(false);

const confirm = useConfirm();
const toast = useToast();

function doApply() {
    const req = {
        name: name.value?.trim(),
        category: selectedSituation.value?.value,
        company: company.value,
        job: jobrole.value,
        email: email.value?.trim(),
        mailing_list: mailingLink.value,
        privacy: acceptingTerm.value,
        message: message.value,
        phone: phone.value
    } as ContactUs;

    if (!req.name || req.name.length < 1) {
        toast.add({severity: 'warn', summary: '양식', detail: '이름을 입력해주세요.', life: 3000})
        return;
    }
    if (!req.email || req.email.length < 1) {
        toast.add({severity: 'warn', summary: '양식', detail: '이메일을 입력해주세요.', life: 3000})
        return;
    }
    if (!req.phone || req.phone.length < 1) {
        toast.add({severity: 'warn', summary: '양식', detail: '전화번호를 입력해주세요.', life: 3000})
        return;
    }
    comms.contactUs.create(req);
    toast.add({ severity: 'info', summary: '신청', detail: '정상적으로 접수되었습니다.', life: 3000 });
    name.value = "";
    company.value = "";
    jobrole.value = "";
    email.value = "";
    phone.value = "";
    selectedSituation.value = undefined;
    message.value = "";
    mailingLink.value = false;
    acceptingTerm.value = false;

}

</script>

<style lang="scss" scoped>
.vhcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hcenter {
    width: 100%;
    display: flex;
    justify-content: center;
}
.vcenter {
    width: 100%;
    display: flex;
    align-items: center;
}
.hright {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.service-view {
    // width: 1366px;
    width: 1900px;
    box-sizing: border-box;
    font-family: NotoSansKR;
    .gnb {
        width: 100%;
        font-size: 14.5px;
    }
    .content {
        padding: 0 384px;
        margin-top: 10rem;
        padding: 0 3rem;
        box-sizing: border-box;
    }
    .work-area {
        width: 100%;
        height: 700px;
        padding: 0 384px;
        font-size: 1.7rem;
        font-weight: bolder;
        .highlight {
            font-size: 1.1rem;
            margin-bottom: 1rem;
            text-align: left;
        }
        .title {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }
    }
    .dummy {
        width: 5rem;
        height: 5rem;
        background-color: grey;
    }
    .section {
        padding: 0 384px;
        margin-top: 3rem;
        font-size: 3rem;
        font-weight: bold;
    }
    .description {
        padding: 0 384px;
        font-size: 1.1rem;
        font-weight: normal;
        line-height: 2;
    }
    .form {
        margin-left: 384px;
        width: 50rem;
        line-height: 2;
        .row {
            width: 100%; display: flex;
            justify-content: space-between;
            margin-bottom: 1.5rem;
        }
    }
}
</style>

<template>
    <div class="knowledge-card" @click="onClick">
        <img class="thumbnail" :src="`${resourceServerUrl}/api/x/file/get/${image}`">
        <div class="info">
            <div>{{ timeToString(updatedAt || 0) }}</div>
            <div style="margin-left: 0.5rem;">{{ category }}</div>
        </div>
        <div class="title">
            {{ title }}
        </div>
        <div class="summary">
            {{ summary }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import { serviceUrl } from '@/comms/utils';

const resourceServerUrl = ref<string>(serviceUrl)

const props = defineProps({
    no: Number,
    updatedAt: Number,
    category: String,
    title: String,
    image: String,
    summary: String,
    content: String
});

const emit = defineEmits<{
    (event: 'click', no: number): void
}>();

function timeToString(time: number) {
    const t = new Date(time);
    return `${t.getFullYear()}/${t.getMonth()+1}/${t.getDate()}`;
}

function onClick() {
    emit("click", props.no || -1);
}

</script>

<style scoped lang="scss">
.knowledge-card {
    width: 500px;
    padding: 20px;
    padding-bottom: 32px;
    cursor: pointer;
    .thumbnail {
        width: 100%;
        max-height: 350px;
        // border-radius: 0.5rem;
        border: 1px solid white;
    }
    .info {
        display: flex;
        justify-content: space-between;
        padding: 0 0.5rem;
        width: 100%;
        font-size: 0.85rem;
        font-weight: bold;
        color: #888;
    }
    .title {
        font-weight: bold;
        font-size: 1.1rem;
        margin: 0.5rem 0;
        color: #231815;
    }
    .summary {
        font-weight: normal;
        font-size: 0.95rem;
        color: #4d5354;
    }

}
</style>
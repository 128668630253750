<template>
    <div class="project-card" @click="onClick">
        <img class="image" :src="`${resourceServerUrl}/api/x/file/get/${image}`">
        <div class="title">
            {{ title }}
        </div>
        <div class="duration">
            <div>{{ timeToString(startDate || 0) }}</div>
            <div style="padding: 0 5px;">~</div>
            <div>{{ timeToString(endDate || 0) }}</div>
        </div>
        <div class="order-from">
            <div>{{ orderOrg }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import { serviceUrl } from '@/comms/utils';

const resourceServerUrl = ref<string>(serviceUrl)

const props = defineProps({
    no: Number,
    image: String,
    title: String,
    startDate: Number,
    endDate: Number,
    orderOrg: String
})

const emit = defineEmits<{
    (event: 'click', no: number): void
}>();

function timeToString(time: number) {
    const t = new Date(time);
    return `${t.getFullYear()}/${t.getMonth()+1}/${t.getDate()}`;
}

function onClick() {
    emit("click", props.no || -1);
}

</script>

<style lang="scss">
    .project-card {
        max-width: 356px;
        max-height: 500px;
        padding: 0.5rem;
        color: black;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .image {
            max-width: 100%;
            max-height: 256px;
            height: 100%;
            align-self: center;
            border: 4px solid white;
        }
        .title {
            font-size: 20px;
            font-weight: bold;
            padding: 10px 0;
            border-bottom: 1px dashed #bbb;
        }
        .duration {
            border-bottom: 1px dashed #bbb;
            padding: 5px 0;
            display: flex;
            justify-content: center;
        }
        .order-from {
            border-bottom: 1px dashed #bbb;
            padding: 5px 0;
            display: flex;
            justify-content: center;
        }
        
    }
</style>
<template>
    <div class="partner-card" @click="onClick">
        <img class="image" :src="`${resourceServerUrl}/api/x/file/get/${image}`" :title="name">
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import { serviceUrl } from '@/comms/utils';

const resourceServerUrl = ref<string>(serviceUrl)

const props = defineProps({
    no: Number,
    image: String,
    name: String,
})

const emit = defineEmits<{
    (event: 'click', no: number): void
}>();

function timeToString(time: number) {
    const t = new Date(time);
    return `${t.getFullYear()}/${t.getMonth()+1}/${t.getDate()}`;
}

function onClick() {
    emit("click", props.no || -1);
}

</script>

<style lang="scss">
    .partner-card {
        max-width: 356px;
        max-height: 500px;
        padding: 0.5rem 1rem;
        color: black;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .image {
            max-width: 100%;
            max-height: 256px;
            height: 100%;
            align-self: center;
            border: 4px solid white;
        }        
    }
</style>
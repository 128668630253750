export default [
    {
        title: "Services",
        route: "service",
        menus: [
            {
                title: "Rd X Innovation",
                route: "innovation"
            },
            {
                title: "Rd X Earch",
                route: "earth"
            }
        ]
    },
    {
        title: "Platforms",
        route: "platforms",
        menus: [
            {
                title: "PICRAS Tool",
                route: "picras"
            }
        ]
    },
    {
        title: "Knowledge",
        route: "knowledge",
        menus: [
            {
                title: "All articles",
                route: "knowledge",
                query: {category: 'all'}
            },
            {
                title: "Report",
                route: "knowledge",
                query: {category: 'report'}
            },
            {
                title: "News & Media",
                route: "knowledge",
                query: {category: 'newsmedia'}
            },
            {
                title: "Knowledge",
                route: "knowledge",
                query: {category: 'knowledge'}
            },
        ]
    },
    {
        title: "Projects",
        route: "projects",
        menus: [
            {
                title: "Projects",
                route: "projects"
            }
        ]
    },
    {
        title: "About us",
        route: "aboutus",
        menus: [
            {
                title: "Our mission and team",
                route: "mission-team"
            },
            {
                title: "Partner and Collaboration",
                route: "partners"
            },
            {
                title: "Contact us",
                route: "contactus"
            },
        ]
    },
];

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ServiceInnovationView from "../views/ServiceInnovationView.vue"
import ServiceEarthView from "../views/ServiceEarthView.vue"
import PICRASToolView from "../views/PICRASToolView.vue"
import OurMissionAndTeamView from "../views/OurMissionAndTeamView.vue"
import PartnersView from "../views/PartnersView.vue"
import ContactUsView from "../views/ContactUsView.vue"
import KnowledgeView from "../views/KnowledgeView.vue"
import ProjectView from "../views/ProjectView.vue"
import KnowledgeContentView from "../views/KnowledgeContentView.vue"
import HomeView from "../views/HomeView.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/service',
    name: 'service',
    redirect: '/service/innovation'
  },
  {
    path: '/service/innovation',
    name: 'innovation',
    component: ServiceInnovationView
  },
  {
    path: '/service/earth',
    name: 'earth',
    component: ServiceEarthView
  },
  {
    path: '/platforms',
    name: 'platforms',
    redirect: '/platforms/picras'
  },
  {
    path: '/platforms/picras',
    name: 'picras',
    component: PICRASToolView
  },
  {
    path: '/aboutus/mission-team',
    name: 'mission-team',
    component: OurMissionAndTeamView
  },
  {
    path: '/aboutus/partners',
    name: 'partners',
    component: PartnersView
  },
  {
    path: '/aboutus/contactus',
    name: 'contactus',
    component: ContactUsView
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    redirect: '/aboutus/mission-team'
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    props: (route) => ({ category: route.query.category }),
    component: KnowledgeView
  },
  {
    path: '/knowledge-content/:no',
    name: 'knowledge-content',
    props: true,
    component: KnowledgeContentView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectView
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

import {ApiResponse, getJson, postJson} from "./utils"

interface Partner {
    no: number;
    index: number;
    category: string;
    name: string;
    image: string;
    user: string;
    created_at: number;
    updated_at: number;
}

interface ListResponse {
    total: number;
    partners: Partner[];
}

class PartnerComms {
    async create(partner: Partner): Promise<ApiResponse<string>> {
        const req: Partner = {...partner};
        return await postJson("/api/partner/create", req);
    }

    async update(know: Partner): Promise<ApiResponse<string>> {
        const req: Partner = {...know};
        return await postJson("/api/partner/update", req);
    }

    async list(page: number, size: number): Promise<ApiResponse<ListResponse>> {
        return await getJson(`/api/x/partner/list?page=${page}&size=${size}`);
    }

    async delete(no: number): Promise<ApiResponse<string>> {
        return await getJson(`/api/partner/delete/${no}`);
    }
}

const partner = new PartnerComms();

export {
    Partner,
    PartnerComms,
}

export default partner;
